import { ADD_SELLER, ADD_SELLER_FAILED, ADD_SELLER_SUCCESSFULL, RESET_ADD_SELLER } from "../constants/addSeller.constants";
import { RESET_ALL_DATA } from "../constants/resetAllData.constants";

const initialState = {
    loading: false,
    success: false,
    data: null,
    error: null,
};

const addSellerReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_SELLER:
            return {
                ...state,
                loading: true,
                success: false,
                data: null,
                error: null,
            };

        case ADD_SELLER_SUCCESSFULL:
            return {
                ...state,
                loading: false,
                success: true,
                data: action.payload,
                error: null
            };

        case ADD_SELLER_FAILED:
            return {
                ...state,
                loading: false,
                success: false,
                data: null,
                error: action.payload
            };

        case RESET_ADD_SELLER:
            return {
                ...initialState
            }

            case RESET_ALL_DATA:
                return{
                    ...initialState
                }

        default:
            return state;
    }
};

export default addSellerReducer;