import React, { useEffect, useState } from 'react';
import { FormControl, Typography, InputLabel, InputBase, Button, Dialog, DialogContent, Avatar, FormControlLabel, Checkbox } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { deleteExistingRule, resetDeleteRule } from '../../redux/actions/deleteRule.actions';
import { fetchRules } from '../../redux/actions/rules.actions';
import { deleteExistingProduct, resetDeleteSingleProduct } from '../../redux/actions/deleteSingleProduct.actions';
import { deleteExistingCompProduct, resetDeleteSingleCompProduct } from '../../redux/actions/deleteSingleCompProduct.actions';
import { fetchProductDetails } from '../../redux/actions/productDetails.actions';
import { useParams } from 'react-router-dom';
import { deleteExistingGroup, resetDeleteGroup } from '../../redux/actions/deleteGroup.actions';
import { fetchGroups } from '../../redux/actions/groups.actions';

const DeleteDialog = ({ dialogState, setDialogState, dialogFor, allRules }) => {

  const themeMode = useSelector(state => state.theme.mode);
  const token = useSelector(state => state.auth.token);
  const deleteSingleProduct = useSelector(state => state.deleteSingleProduct);
  const deleteSingleCompProductDetails = useSelector(state => state.deleteSingleCompProductDetails);
  const deleteRule = useSelector(state => state.deleteRule);
  const deleteGroupData = useSelector(state => state.deleteGroupDetails);
  const uniqueRuleNames = allRules?.data?.filter((grp) => grp.group?.group_name === dialogState?.data?.group_name)?.map((grp) => grp?.rule_name) || [];

  const dispatch = useDispatch();
  const { product_id } = useParams();

  const [deleting, setDeleting] = useState(false)
  const [serverMsg, setServerMsg] = useState('')
  const [isChecked, setIsChecked] = useState(false);

  const confirmSchema = Yup.object().shape({
    confirm: Yup.string().required('Required')
      .test('is-delete-confirmed', 'Please enter DELETE to confirm deletion', value => value === 'DELETE'),
  })

  const handleCloseDelete = () => {
    setDialogState({ ...dialogState, [dialogFor]: false, data: null })
  };

  const handleSubmit = () => {
    if (dialogFor === 'deleteSingleProduct') {
      setDeleting(true);
      dispatch(deleteExistingProduct(token, dialogState.data?.prd_id))
    } else if (dialogFor === 'deleteRule') {
      setDeleting(true)
      dispatch(deleteExistingRule(token, dialogState.data));
    } else if (dialogFor === 'deleteSingleComp') {
      setDeleting(true)
      dispatch(deleteExistingCompProduct(token, dialogState?.data?.comp_prd_id));
    } else if (dialogFor === 'deleteGroup') {
      setDeleting(true)
      dispatch(deleteExistingGroup(token, dialogState?.data?.group_id));
    } else if (dialogFor === 'deleteCompPdt') {
      if (isChecked) {
        console.log("checked api call")
        console.log("dialogState", dialogState)
        setDeleting(true);
        dispatch(deleteExistingCompProduct(token, dialogState?.deleteCompPdt?.data?.comp_prd_id));
      } else {
        dialogState?.deleteCompPdt?.onConfirm()
        handleCloseDelete()
      }
    }
  }

  useEffect(() => {
    if (deleteSingleProduct.data) {
      handleCloseDelete();
      setDeleting(false)
      dispatch(resetDeleteSingleProduct());
    } else if (deleteSingleProduct.error) {
      setServerMsg('Failed to delete product')
      setDeleting(false)
    }
  }, [deleteSingleProduct]) //eslint-disable-line

  useEffect(() => {
    if (deleteRule.data) {
      handleCloseDelete();
      setDeleting(false)
      dispatch(resetDeleteRule());
      dispatch(fetchRules(token));
    } else if (deleteRule.error) {
      setServerMsg('Failed to delete rule')
      setDeleting(false)
    }
  }, [deleteRule]) //eslint-disable-line

  useEffect(() => {
    if (deleteGroupData.data) {
      if(deleteGroupData.data && uniqueRuleNames ){
        dispatch(fetchRules(token));
      }
      handleCloseDelete();
      setDeleting(false)
      dispatch(resetDeleteGroup());
      dispatch(fetchGroups(token));
    } else if (deleteGroupData.error) {
      setServerMsg('Failed to delete Group')
      setDeleting(false)
    }
  }, [deleteGroupData]) //eslint-disable-line

  useEffect(() => {
    if (deleteSingleCompProductDetails.data) {
      handleCloseDelete();
      setDeleting(false)
      dispatch(fetchProductDetails(token, product_id));
      dispatch(resetDeleteSingleCompProduct());
    } else if (deleteSingleCompProductDetails.error) {
      setServerMsg('Failed to delete product')
      setDeleting(false)
    }
  }, [deleteSingleCompProductDetails]) //eslint-disable-line

  const fontColor = { color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }
  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(2),
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: 'transparent',
      border: '1px solid #ced4da',
      fontSize: 11,
      width: '100%',
      padding: '10px 12px',
      ...fontColor,
      transition: theme.transitions.create([
        'border-color',
        'background-color',
      ]),
      fontFamily: 'Inter',
      '&:focus': {
        borderColor: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
      },
    },
  }));
  const ipLabel = {
    ...fontColor,
    fontSize: '15px', fontWeight: 'bold', mt: 2
  }
  const warningMsg = { ...fontColor, fontSize: '11px', mt: 2 }

  return (
    <>
      <Dialog
        open={dialogState.deleteNotification || dialogState.deleteSingleProduct || dialogState.deleteRule || dialogState.deleteSingleComp || dialogState.deleteGroup || dialogState.deleteCompPdt.show }
        onClose={handleCloseDelete}
        PaperProps={{ sx: { backgroundColor: themeMode === 'dark' ? '#232D3B' : 'white', width: '400px' } }}
      >
        <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
          {deleting ? <Typography sx={{ ...fontColor, m: 5, fontSize: '16px', fontWeight: 'bold' }}>Deleting, please wait...</Typography> : <>
            <Avatar sx={{ bgcolor: '#FEE4E2' }}>
              <ErrorOutlineIcon color='error' />
            </Avatar>
            {dialogFor === 'deleteCompPdt' && <Typography sx={ipLabel}>Delete Competitor Product ({dialogState?.deleteCompPdt?.data?.market_places?.market_place_name})</Typography>}
            {dialogFor === 'deleteNotification' && <Typography sx={ipLabel}>Delete Notification</Typography>}
            {dialogFor === 'deleteSingleProduct' && <Typography sx={ipLabel}>Delete Product</Typography>}
            {dialogFor === 'deleteSingleComp' && <Typography sx={ipLabel}>Delete Competitor Product ({dialogState?.data?.market_places?.market_place_name})</Typography>}
            {dialogFor === 'deleteRule' && <Typography sx={ipLabel}>Delete Rule</Typography>}
            {dialogFor === 'deleteGroup' && <Typography sx={ipLabel}>Delete Group</Typography>}
            {dialogFor === 'deleteNotification' && <Typography sx={warningMsg}>
              Are you sure that you want to stop sending notifications to {dialogState.data?.email} ? Enter DELETE to continue.</Typography>}
            {dialogFor === 'deleteSingleProduct' && <Typography sx={warningMsg}>
              Are you sure that you want to delete the product <b>{dialogState.data?.prd_name}</b> ? <span style={{color: 'red', fontWeight: 'bold'}} >This cannot be undone.</span> Enter DELETE to continue.</Typography>}
            {dialogFor === 'deleteCompPdt' && <Typography sx={warningMsg}>
              Are you sure that you want to delete the product <b>{dialogState.deleteCompPdt?.data?.comp_prd_name}</b> ?</Typography>}
            {dialogFor === 'deleteRule' && <Typography sx={warningMsg}>
              Are you sure that you want to delete <b>{dialogState.data?.rule_name}</b> rule? <span style={{color: 'red', fontWeight: 'bold'}} >This cannot be undone.</span> Enter DELETE to continue.</Typography>}
            {dialogFor === 'deleteGroup' && (
             <Typography sx={warningMsg}>
                Are you sure that you want to delete <b>{dialogState.data?.group_name}</b> group? {` `}
                {uniqueRuleNames?.length > 0 && (
                  <span style={{color:'red'}}>
                    <br />This group is associated with rules {uniqueRuleNames.map((obj, index) => (
                      <span key={index} style={{fontWeight: 'bold'}} >{obj?.charAt(0).toUpperCase() + obj.slice(1)}{index < uniqueRuleNames.length - 1 ? ', ' : ''}</span>
                    ))}; 
                    if the group is deleted, these rules will also be removed.<br />
                  </span>
                )}
                <span style={{color: 'red', fontWeight: 'bold'}} >This cannot be undone.</span> Enter DELETE to continue.
             </Typography>
            )}
            {dialogFor === 'deleteSingleComp' && <Typography sx={warningMsg}>
              Are you sure that you want to delete <b>{dialogState.data?.comp_prd_name}</b> ? <span style={{color: 'red', fontWeight: 'bold'}} >This cannot be undone.</span> Enter DELETE to continue.</Typography>}
              {
              !dialogState?.deleteCompPdt?.show && 
              <Formik
                initialValues={{ confirm: '' }}
                validationSchema={confirmSchema}
                onSubmit={(values) => handleSubmit(values)}
              >
                {({ errors, setFieldValue, values }) => (
                  <Form className='loginform'>
                    <FormControl variant='standard' sx={{ width: '100%' }} >
                      <InputLabel shrink htmlFor='confirm' sx={{
                        ...fontColor, fontSize: '11px',
                        '&.Mui-focused': fontColor
                      }}>Confirm
                      </InputLabel>
                      <BootstrapInput
                        type='text'
                        value={values.confirm}
                        placeholder='Enter DELETE'
                        id='confirm'
                        name='confirm'
                        onChange={e => setFieldValue('confirm', e.target.value)}
                      />
                      <Typography sx={{ fontSize: '11px', mt: 1 }} color='red' variant='subtitle'>{errors.confirm}</Typography>
                    </FormControl>
                    <Typography sx={{ fontSize: '11px', color: 'red' }}>{serverMsg}</Typography>
                    <FormControl sx={{ m: 0, mt: 3, mb: 2, display: 'flex', justifyContent: 'space-between', flexFlow: 'row', gap: '17px' }}>
                      <Button onClick={() => handleCloseDelete()} variant='outlined' sx={{ width: 140, textTransform: 'none', color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }}>Cancel</Button>
                      <Button disabled={errors.confirm || !values.confirm} variant='contained' color='error' sx={{ width: 140, textTransform: 'none' }} type='submit' >Confirm</Button>
                    </FormControl>
                  </Form>
                )}
              </Formik>
              }

              {
                dialogState?.deleteCompPdt?.show &&
                <Formik
                initialValues={{ confirm: '' }}
                onSubmit={(values) => handleSubmit(values)}
              >
                {({ errors, setFieldValue, values }) => (
                  <Form className='loginform'>
                    <FormControl variant='standard' sx={{ width: '100%' }}>
                      <FormControlLabel
                        control={<Checkbox size='small' sx={{...fontColor,
                          '& .MuiSvgIcon-root': { 
                              fontSize: '18px'
                          },
                      }} checked={isChecked} onChange={(event) => setIsChecked(event.target.checked)} />}
                        label={
                          <Typography sx={{ color: 'red', fontSize: '11px', fontWeight: 'bold' }}>
                            Permanently delete (This cannot be undone)
                          </Typography>
                        }
                      />
                      <Typography sx={{ fontSize: '11px', mt: 1 }} color='red' variant='subtitle'>
                        {errors.confirm}
                      </Typography>
                    </FormControl>
                    <Typography sx={{ fontSize: '11px', color: 'red' }}>{serverMsg}</Typography>
                    <FormControl
                      sx={{ m: 0, mt: 3, mb: 2, display: 'flex', justifyContent: 'space-between', flexFlow: 'row', gap: '17px' }}>
                      <Button
                        onClick={handleCloseDelete}
                        variant='outlined'
                        sx={{ width: 140, textTransform: 'none', color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }}
                      >
                        Cancel
                      </Button>
                      <Button variant='contained' color='error'
                        sx={{ width: 140, textTransform: 'none' }}
                        type='submit'
                      >
                        {isChecked ? 'Delete' : 'Remove'}
                      </Button>
                    </FormControl>
                  </Form>
                )}
              </Formik>
                      }
            
            </>}
        </DialogContent>
      </Dialog>
    </>
  )
}

export default DeleteDialog;