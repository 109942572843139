import { Box, Dialog, DialogContent, Grid, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';

const ViewGroupDetails = ({ dialogState, setDialogState }) => {

  const themeMode = useSelector(state => state.theme.mode);

  const handleClose = () => {
    setDialogState((prev) => ({
      ...prev,
      viewDetails: false,
      data: null
    }));
  };

  const fontColor = { color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }
  const valueFont = { fontSize: '11px', fontWeight: "bold" }
  const mainGrid = { display: 'flex', fontSize: '11px', alignItems: 'center' }

  return (
    <Dialog
      open={dialogState.viewDetails}
      onClose={handleClose}
      PaperProps={{
        sx: {
          backgroundColor: themeMode === 'dark' ? '#232D3B' : 'white', width: "380px"
        },
      }}
    >
      <DialogContent sx={fontColor} >
        <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>Group Details</Typography>
          <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer', fontSize: '20px' }} />
        </Grid>

        <Grid sx={{mt: 3}} container>
          
          <Grid item xs={12} container style={mainGrid} >
            <Grid xs={7} >
              <Box sx={{display: 'flex', alignItems: 'center'}} >
              <KeyboardDoubleArrowRightOutlinedIcon color='primary' sx={{ fontSize: '20px' }} />
              <Typography sx={{fontSize: '11px'}}>Product Count:</Typography>
              </Box>
            </Grid>
            <Grid item xs={5}><Typography style={valueFont} >{dialogState?.data?.product_count}</Typography></Grid>
          </Grid>

          <Grid item xs={12} container style={mainGrid} >
            <Grid xs={7} >
            <Box sx={{display: 'flex', alignItems: 'center'}} >
              <KeyboardDoubleArrowRightOutlinedIcon color='primary' sx={{ fontSize: '20px' }} />
              <Typography sx={{fontSize: '11px'}}>Group Name:</Typography>
            </Box>
            </Grid>
            <Grid item xs={5}><Typography style={{...valueFont, textTransform: 'capitalize'}} >{dialogState?.data?.group_name}</Typography></Grid>
          </Grid>

          <Grid item xs={12} container style={mainGrid} >
            <Grid xs={7} >
            <Box sx={{display: 'flex', alignItems: 'center'}} >
              <KeyboardDoubleArrowRightOutlinedIcon color='primary' sx={{ fontSize: '20px' }} />
              <Typography sx={{fontSize: '11px'}}>Group Desc:</Typography>
            </Box>
            </Grid>
            <Grid item xs={5}><Typography style={valueFont} >{dialogState?.data?.group_desc ? dialogState?.data?.group_desc?.charAt(0)?.toUpperCase() + dialogState?.data?.group_desc?.slice(1) : ''}</Typography></Grid>
          </Grid>
          
          <Grid item xs={12} container style={mainGrid} >
            <Grid xs={7} >
            <Box sx={{display: 'flex', alignItems: 'center'}} >
              <KeyboardDoubleArrowRightOutlinedIcon color='primary' sx={{ fontSize: '20px' }} />
              <Typography sx={{fontSize: '11px'}}>Created on:</Typography>
            </Box>
            </Grid>
            <Grid item xs={5}><Typography style={valueFont} >{dialogState?.data?.created_date ? new Date(dialogState?.data?.created_date)?.toLocaleString() : '--'}</Typography></Grid>
          </Grid>

        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default ViewGroupDetails;