import { ADD_LOCATION, ADD_LOCATION_FAILED, ADD_LOCATION_SUCCESSFULL, RESET_ADD_LOCATION } from "../constants/addLocation.constants";
import { RESET_ALL_DATA } from "../constants/resetAllData.constants";


const initialState = {
    loading: false,
    success: false,
    data: null,
    error: null,
};

const addLocationReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_LOCATION:
            return {
                ...state,
                loading: true,
                success: false,
                data: null,
                error: null,
            };

        case ADD_LOCATION_SUCCESSFULL:
            return {
                ...state,
                loading: false,
                success: true,
                data: action.payload,
                error: null
            };

        case ADD_LOCATION_FAILED:
            return {
                ...state,
                loading: false,
                success: false,
                data: null,
                error: action.payload
            };

        case RESET_ADD_LOCATION:
            return {
                ...initialState
            }

            case RESET_ALL_DATA:
                return{
                    ...initialState
                }

        default:
            return state;
    }
};

export default addLocationReducer;