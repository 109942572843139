import React from 'react'
import { Dialog, Divider, Grid, IconButton, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { Line } from 'react-chartjs-2';

const OttixInsightsDialog = ({dialogState,setDialogState}) => {

  const themeMode = useSelector(state => state.theme.mode);
  
  // Dummy data
  const data = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        label: 'Historical Data',
        data: [65, 59, 80, 81, 56, 55, 40],
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1,
      },
    ],
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    barThickness: 15,
    interaction: {
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          drawOnChartArea: false,
        },
        ticks: {
          maxRotation: 0,
          autoSkip: false,
          color: themeMode === 'dark' ? '#FFFFFF' : '#272727',
        },
        border: {
          display: true,
          color: '#515151',
        },
      },
      y: {
        stacked: true,
        labelAngle: 0,
        grid: {
          drawOnChartArea: false,
        },
        ticks: {
          maxRotation: 0,
          autoSkip: false,
          color: themeMode === 'dark' ? '#FFFFFF' : '#272727',
        },
        border: {
          display: true,
          color: '#515151',
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'top',
        align: 'end',
        labels: {
          usePointStyle: true,
          font: {
            size: 11,
          },
          color: themeMode === 'dark' ? '#FFFFFF' : '#272727',
        },
        onClick: () => { } // Disable legend click functionality
      },
      datalabels: {
        display: false,
      },
    },
  };

  const handleCloseOttixInfoDialog = () => {
    setDialogState({ ...dialogState, openGraphInfo: false })
  };

  return (
    <Dialog
    open={dialogState.openGraphInfo} onClose={handleCloseOttixInfoDialog} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'
    PaperProps={{ style: { width: '90%', maxWidth: '90%', margin: 0, background: themeMode === 'dark' ? '#232D3B' : 'white', color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' } }}
    >
    <Grid container sx={{ overflowY: 'auto' }} className={`custom-scrollbar-${themeMode}`}>
        <Grid xs={12} item container sx={{display: 'flex', justifyContent: 'space-between', p: 1, pt: '20px', fontWeight: 'bold'}} >
            <Typography sx={{fontWeight: 'bold'}} >Ottix Insights</Typography>
            <IconButton onClick={handleCloseOttixInfoDialog} ><CloseIcon sx={{ cursor: 'pointer', color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A', fontSize: '20px' }} /></IconButton>
        </Grid>
        <Grid item xs={12} ><Divider sx={{ backgroundColor: themeMode === 'dark' ? '#E4E3E3' : 'lighGrey' }} /></Grid>
        <Grid xs={12} item container padding={2} >
            <Grid xs={6} style={{ position: 'relative' }} item sx={{ p: 4, height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}} >
            <div style={{ filter: 'blur(10px)', background: themeMode === 'dark' ? '#1a222d' : '#eaecee', boxShadow: 'none', height: '100%', width: '100%', position: 'absolute', top: 0, left: 0 }}></div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', boxShadow: 'none', zIndex: 1, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
              <Typography sx={{ fontSize: '10px', textAlign: 'center', fontWeight: 'bold' }}>Coming Soon...</Typography>
              <Typography sx={{ fontSize: '13px', textAlign: 'center', fontWeight: 'bold' }}>Recommended Price</Typography>
            </div>
            </Grid>
            <Grid xs={6} item sx={{ p: 4, height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', position: 'relative' }}>
              <div style={{ height: '200px', width: "100%", filter: 'blur(2px)' }}>
                <Line data={data} options={options} />
              </div>
              <Typography sx={{ fontSize: '10px', textAlign: 'center', fontWeight: 'bold', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1 }}>
                Coming Soon...
              </Typography>
            </Grid>            
            <Grid xs={6} item style={{ position: 'relative' }} sx={{ p: 4, height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}} >
            <div style={{ filter: 'blur(10px)', background: themeMode === 'dark' ? '#1a222d' : '#eaecee', boxShadow: 'none', height: '100%', width: '100%', position: 'absolute', top: 0, left: 0 }}></div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', boxShadow: 'none', zIndex: 1, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
              <Typography sx={{ fontSize: '10px', textAlign: 'center', fontWeight: 'bold' }}>Coming Soon...</Typography>
              <Typography sx={{ fontSize: '13px', textAlign: 'center', fontWeight: 'bold' }}>Product Description Suggestion</Typography>
            </div>
            </Grid>
            <Grid xs={6} item style={{ position: 'relative' }} sx={{ p: 4, height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}} >
            <div style={{ filter: 'blur(10px)', background: themeMode === 'dark' ? '#1a222d' : '#eaecee', boxShadow: 'none', height: '100%', width: '100%', position: 'absolute', top: 0, left: 0 }}></div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', boxShadow: 'none', zIndex: 1, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
              <Typography sx={{ fontSize: '10px', textAlign: 'center', fontWeight: 'bold' }}>Coming Soon...</Typography>
              <Typography sx={{ fontSize: '13px', textAlign: 'center', fontWeight: 'bold' }}>AI Reviews</Typography>
            </div>
            </Grid>
        </Grid>
    </Grid>
  </Dialog>
  )
}

export default OttixInsightsDialog