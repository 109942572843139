import { EDIT_USER, EDIT_USER_FAILED, EDIT_USER_SUCCESSFULL, RESET_EDIT_USER } from "../constants/editUser.constants";
import { RESET_ALL_DATA } from "../constants/resetAllData.constants";

const initialState = {
    loading: false,
    success: false,
    data: null,
    error: null,
};

const editUserReducer = (state = initialState, action) => {
    switch (action.type) {
        case EDIT_USER:
            return {
                ...state,
                loading: true,
                success: false,
                data: null,
                error: null,
            };

        case EDIT_USER_SUCCESSFULL:
            return {
                ...state,
                loading: false,
                success: true,
                data: action.payload,
                error: null
            };

        case EDIT_USER_FAILED:
            return {
                ...state,
                loading: false,
                success: false,
                data: null,
                error: action.payload
            };

        case RESET_EDIT_USER:
            return {
                ...initialState
            }

            case RESET_ALL_DATA:
                return{
                    ...initialState
                }

        default:
            return state;
    }
};

export default editUserReducer;