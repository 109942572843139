import React from "react";
import "./spinLoader.css"

const SpinLoader = () => {
  return (
    <div className="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default SpinLoader;
