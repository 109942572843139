import { Typography } from '@mui/material';
import React from 'react';

const PricevarianceChart = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }} >
      <Typography sx={{ fontSize: '12px' }} >Price Variance Chart</Typography>
      <Typography sx={{ fontSize: '11px', pt: 2 }} >Coming Soon...</Typography>
    </div>
  )
}

export default PricevarianceChart;
