import { Avatar, Box, Button, Grid, InputBase, MenuItem, Select, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAllProductLocations } from '../../redux/actions/productLocations.actions';
import { changeDefaultLocation } from '../../redux/actions/setDefaultLocation.actions';
import { resetChangePassword } from '../../redux/actions/changePassword.actions';
import DeleteWithPasswordDialog from '../DeleteWithPasswordDialog';

const GeneralSettings = () => {

  const themeMode = useSelector(state => state.theme.mode);
  const token = useSelector(state => state.auth.token);
  const locations = useSelector(state => state.productLocations.data);
  const setDefLoc = useSelector(state => state.setDefaultLocation);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [location, setLocation] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [serverMsg, setServerMsg] = useState('')
  const [dialogState, setDialogState] = useState({ deleteAllProducts: false });

  const handleLocationChange = (event) => {
    setServerMsg('');
    setSubmitting(true);
    dispatch(changeDefaultLocation(token, event.target.value))
  };

  const openDialog = () => {
    setDialogState({ deleteAllProducts: true });
  }

  useEffect(() => {
    if (locations) {
      let def_loc = '';
      locations?.forEach((loc) => {
        if (loc.loc_is_default) def_loc = loc.loc_id
      })
      setLocation(def_loc);
      setSubmitting(false)
    }
  }, [locations]) //eslint-disable-line

  useEffect(() => {
    if (setDefLoc.data || !locations) {
      setSubmitting(false);
      dispatch(getAllProductLocations(token));
      dispatch(resetChangePassword());
    } else if (setDefLoc.error) {
      setSubmitting(false);
      setServerMsg('Failed to change default location')
    }
  }, [setDefLoc]) //eslint-disable-line

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    '& .MuiSelect-icon': {
      fill: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A'
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: 'transparent',
      border: '1px solid #ced4da',
      fontSize: 11,
      width: '100%',
      padding: '8px 12px',
      color: themeMode === 'dark' ? 'white' : 'black',
      fontFamily: 'Inter',
      '&:focus': {
        borderColor: themeMode === 'dark' ? 'white' : 'black',
      },
    },
  }));
  const selectStyle = {
    color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A',
    bgcolor: themeMode === 'light' ? '#eaecee' : '#1A222D'
  }

  return (
    <>
      {dialogState.deleteAllProducts && <DeleteWithPasswordDialog
        dialogState={dialogState} setDialogState={setDialogState} dialogFor='deleteAllProducts' />}
      <Grid pl={3} my={4} sx={{
        backgroundColor: themeMode === 'dark' ? '#232D3B' : '#fff',
        display: 'flex', alignItems: 'center', height: '70px', borderRadius: '10px'
      }}>
        <Avatar>
          <PlaceOutlinedIcon sx={{ color: 'black' }} />
        </Avatar>
        <div>
          <Typography sx={{ fontSize: '16px', ml: 2, fontWeight: 'bold' }}>Default Location</Typography>
          <Typography sx={{ fontSize: '12px', ml: 2 }}>Please select your default location</Typography>
        </div>
        <Box ml='auto' width='31%'>
          <Select
            size='small'
            variant='outlined'
            value={location}
            onChange={handleLocationChange}
            MenuProps={{
              PaperProps: {
                sx: selectStyle
              }
            }}
            input={<BootstrapInput
              id='location-select'
              name='location-select'
            />}
            sx={{ width: "90%", fontSize: 11, fontFamily: 'Inter', color: '#E4E3E3', fontWeight: 400, }}>
            <MenuItem sx={{ fontSize: 11, }} value='location' disabled>
              Select Location
            </MenuItem>
            {locations?.map((item, index) => (
              <MenuItem sx={{ fontSize: '12px' }} key={index} value={item.loc_id}>
                {`${item.loc_name} - ${item.loc_zip}`}
              </MenuItem>
            ))}
          </Select>
          {submitting && <Typography sx={{ fontSize: '11px', mt: 1 }}>Changing the default location, please wait...</Typography>}
          <Typography sx={{ fontSize: '11px', mt: 1, color: 'red' }}>{serverMsg}</Typography>
        </Box>
      </Grid>
      <Typography sx={{ fontSize: '15px' }}>ADVANCED SETTINGS</Typography>
      <Grid onClick={() => navigate('/changePassword')} pl={3} my={2} sx={{
        backgroundColor: themeMode === 'dark' ? '#232D3B' : '#fff',
        display: 'flex', alignItems: 'center', height: '70px', borderRadius: '10px', cursor: 'pointer'
      }}>
        <Avatar>
          <LockOutlinedIcon sx={{ color: 'black' }} />
        </Avatar>
        <div>
          <Typography sx={{ fontSize: '16px', ml: 2, fontWeight: 'bold' }}>Change Password</Typography>
        </div>
        <ChevronRightIcon sx={{ ml: 'auto', mr: '10px' }} />
      </Grid>
      <Grid pl={3} my={2} sx={{
        backgroundColor: themeMode === 'dark' ? '#232D3B' : '#fff',
        display: 'flex', alignItems: 'center', height: '70px', borderRadius: '10px'
      }}>
        <Avatar>
          <DeleteOutlineOutlinedIcon sx={{ color: 'black' }} />
        </Avatar>
        <div>
          <Typography sx={{ fontSize: '16px', ml: 2, fontWeight: 'bold' }}>Delete All Products</Typography>
        </div>
        <Button sx={{ ml: 'auto', mr: '20px', fontWeight: 'bold', textTransform: 'none' }}
          variant='outlined' color='error' onClick={openDialog}>
          Delete
        </Button>
      </Grid>
    </>
  )
};

export default GeneralSettings;