import { SIGN_UP, RESET_SIGN_UP, SIGN_UP_FAILED, SIGN_UP_SUCCESSFULL } from "../constants/signUp.constants";
import { RESET_ALL_DATA } from "../constants/resetAllData.constants";

const initialState = {
    loading: false,
    data: null,
    error: null,
};

const signUpReducer = (state = initialState, action) => {
    switch (action.type) {
        case SIGN_UP:
            return {
                ...state,
                loading: true,
                error: null,
            };

        case SIGN_UP_SUCCESSFULL:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null
            };

        case SIGN_UP_FAILED:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload
            };

        case RESET_SIGN_UP:
            return {
                ...initialState
            }
            
        case RESET_ALL_DATA:
            return{
                ...initialState
            }

        default:
            return state;
    }
}

export default signUpReducer;