import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, RESET_AUTH, RESTORE_AUTH, SET_QA_CLIENT, TOGGLE_PRODUCTS } from '../constants/loginUser.constants';

const initialState = {
  user: null,
  loading: false,
  error: null,
  token: null,
  productsCreated: null,
  currency: null,
  role: null,
  id: null,
  UI_design: null,
  user_id: null,
  company_logo: null,
  company_id: null,
  qa_user: false,
  admin_approved: null
};

const loginUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.user,
        token: action.payload.token,
        productsCreated: action.payload.productsCreated,
        currency: action.payload.currency,
        role: action.payload.role,
        id: action.payload.id,
        UI_design: action.payload.UI_design,
        user_id: action.payload.user_id,
        company_logo: action.payload.company_logo,
        admin_approved: action.payload.admin_approved
      };

    case LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SET_QA_CLIENT:
      const { company_id, currency, user_id } = action.payload;
      const tempState = { ...state, qa_user: true, company_id, currency, user_id}
      sessionStorage.setItem('userDetails', JSON.stringify(tempState));
      return tempState

    case RESET_AUTH:
      return {
        ...initialState,
      };
      
    case TOGGLE_PRODUCTS:
      return {
        ...state,
        productsCreated: true
      };

    case RESTORE_AUTH:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default loginUserReducer;