import React from 'react';
import { Grid, Tooltip } from '@mui/material';

const PrivateNationalRetailer = () => {

    const tableData = [
        { id: '1', title: 'CVS', pvtPercent: 3.1, natPercent: 90 },
        { id: '2', title: 'Dollar General', pvtPercent: 0.6, natPercent: 96.12 },
        { id: '3', title: 'Walgreens', pvtPercent: 1.8, natPercent: 50 },
    ];

    const maxPrivateValue = Math.max(...tableData.map(item => item.pvtPercent));
    const maxNatValue = Math.max(...tableData.map(item => item.natPercent));

    // Determine the interval for displaying scale values
    const Pvtinterval = Math.ceil(maxPrivateValue / 10);
    const Natinterval = Math.ceil(maxNatValue / 10);

    // Generate scale values from 0 to the maximum value
    const PvtscaleValues = Array.from({ length: maxPrivateValue + 1 }, (_, i) => i);
    const NatscaleValues = Array.from({ length: maxNatValue + 1 }, (_, i) => i);

    return (
        <Grid container sx={{ border: "2px solid black", overflowX: 'auto' }}>
            <Grid item xs={12} sx={{ textAlign: "center", fontWeight: "bold", border: '1px solid black', padding: "8px", backgroundColor: "#070544", color: "white", fontSize: "14px" }}>
                Private Label % vs National Product % by Retailer
            </Grid>
            <Grid item xs={12}>
                {tableData.map((item, itemIndex) => (
                    <Grid container spacing={0} key={item.id} sx={{ border: '1px solid black' }}>
                        <Grid item xs={2} sx={{ padding: '16px', fontSize: '10px', borderRight: '1px solid black', background: (itemIndex % 2 === 0) ? "#f2f2f2" : "white" }}>
                            {item.title}
                        </Grid>
                        <Grid item xs={5} sx={{ display:"flex", alignItems:"center", padding: '16px', fontSize: '10px', borderRight: '1px solid black', background: (itemIndex % 2 === 0) ? "#f2f2f2" : "white" }}>
                            <Tooltip title={`Private Label: ${item.pvtPercent}%`}>
                                <div className="scale-in-hor-left" style={{ width: `${(item.pvtPercent / maxPrivateValue) * 100}%`, backgroundColor: '#5500ff', height: '4px', position: 'relative', cursor: "pointer" }}>
                                    <div style={{ position: 'absolute', width: '26px', height: '26px', backgroundColor: '#a198ff', borderRadius: '50%', bottom: '-8px', right: '-1px', fontSize: '9px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >{item.pvtPercent}</div>
                                </div>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={5} sx={{ display:"flex", alignItems:"center", padding: '16px', fontSize: '10px', borderRight: '1px solid black', background: (itemIndex % 2 === 0) ? "#f2f2f2" : "white" }}>
                            <Tooltip title={`National Product: ${item.natPercent}%`}>
                                <div className="scale-in-hor-left" style={{ width: `${(item.natPercent / maxNatValue) * 100}%`, backgroundColor: '#3cba01', height: '4px', position: 'relative', cursor: "pointer" }}>
                                    <div style={{ position: 'absolute', width: '26px', height: '26px', backgroundColor: '#a8f17b', borderRadius: '50%', bottom: '-8px', right: '-1px', fontSize: '9px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >{item.natPercent}</div>
                                </div>
                            </Tooltip>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
            <Grid item xs={12} container sx={{ borderTop: '1px solid black' }}>
                <Grid item xs={2} sx={{background: 'white'}} />
                <Grid item xs={5} sx={{ fontWeight: 'bold', fontSize: '13px', padding: "8px", background: 'white' }}>
                    <div style={{ position: 'relative', height: "20px" }}>
                        {PvtscaleValues.map((value, index) => (
                            index % Pvtinterval === 0 && (
                                <React.Fragment key={index}>
                                    <span style={{ position: 'absolute', left: `${(value / maxPrivateValue) * 100}%`, top: '100%', transform: 'translate(-50%, -50%)', fontSize: '10px' }}>{value}</span>
                                </React.Fragment>
                            )
                        ))}
                    </div>
                </Grid>
                <Grid item xs={5} sx={{ fontWeight: 'bold', fontSize: '13px', padding: "8px", background: 'white' }}>
                    <div style={{ position: 'relative', height: "20px" }}>
                        {NatscaleValues.map((value, index) => (
                            index % Natinterval === 0 && (
                                <React.Fragment key={index}>
                                    <span style={{ position: 'absolute', left: `${(value / maxNatValue) * 100}%`, top: '100%', transform: 'translate(-50%, -50%)', fontSize: '10px' }}>{value}</span>
                                </React.Fragment>
                            )
                        ))}
                    </div>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default PrivateNationalRetailer;
