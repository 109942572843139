import { DELETE_SINGLE_COMP_PPRODUCT,DELETE_SINGLE_COMP_PRODUCT_FAILED,DELETE_SINGLE_COMP_PRODUCT_SUCCESSFULL,RESET_DELETE_SINGLE_COMP_PRODUCT } from "../constants/deleteSingleCompProduct.constants";
import { RESET_ALL_DATA } from "../constants/resetAllData.constants";

const initialState = {
    loading: false,
    success: false,
    data: null,
    error: null,
};

const deleteSingleCompProductReducer = (state = initialState, action) => {
    switch (action.type) {
        case DELETE_SINGLE_COMP_PPRODUCT:
            return {
                ...state,
                loading: true,
                success: false,
                data: null,
                error: null,
            };

        case DELETE_SINGLE_COMP_PRODUCT_SUCCESSFULL:
            return {
                ...state,
                loading: false,
                success: true,
                data: action.payload,
                error: null
            };

        case DELETE_SINGLE_COMP_PRODUCT_FAILED:
            return {
                ...state,
                loading: false,
                success: false,
                data: null,
                error: action.payload
            };

        case RESET_DELETE_SINGLE_COMP_PRODUCT:
            return {
                ...initialState
            }

            case RESET_ALL_DATA:
                return{
                    ...initialState
                }

        default:
            return state;
    }
};

export default deleteSingleCompProductReducer;