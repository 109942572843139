import React from 'react'
import { Box, Dialog, DialogContent, Grid, Typography } from '@mui/material'
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';


const ViewRuleDetails = ({ dialogState, setDialogState }) => {

  const themeMode = useSelector(state => state.theme.mode);

  const binaryStringToWeekdays = (binaryString) => {
    const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const selectedDays = [];

    for (let i = 0; i < binaryString.length; i++) {
      if (binaryString[i] === '1') {
        selectedDays.push(weekDays[i]);
      }
    }

    return selectedDays;
  };

  const handleClose = () => {
    setDialogState((prev) => ({
      ...prev,
      viewDetails: false,
      data: null
    }));
  };

  const fontColor = { color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }
  const valueFont = { fontSize: '11px', fontWeight: "bold" }
  const mainGrid = { display: 'flex', fontSize: '11px', justifyContent: 'center', alignItems: 'center' }

  return (
    <Dialog
      open={dialogState.viewDetails}
      onClose={handleClose}
      PaperProps={{
        sx: {
          backgroundColor: themeMode === 'dark' ? '#232D3B' : 'white', width: "350px"
        },
      }}
    >
      <DialogContent sx={fontColor} >
        <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>Rule Details</Typography>
          <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer', fontSize: '20px' }} />
        </Grid>
        <Grid sx={{ mt: 3 }} container >
          <Grid item xs={12} sx={mainGrid} >
            <Grid item xs={8}>
              <Box sx={{ display: 'flex', mt: '6px', alignItems: 'center' }} >
              <KeyboardDoubleArrowRightOutlinedIcon color='primary' sx={{ fontSize: '20px' }} />
              <Typography sx={{fontSize: '11px'}}>Created Date:</Typography>
              </Box>
            </Grid>
            <Grid item xs={4}><Typography style={valueFont} >{new Date(dialogState?.data?.created_date).toLocaleDateString()}</Typography></Grid>
          </Grid>

          <Grid item xs={12} sx={mainGrid} >
            <Grid item xs={8}>
              <Box sx={{ display: 'flex', mt: '6px', alignItems: 'center' }} >
              <KeyboardDoubleArrowRightOutlinedIcon color='primary' sx={{ fontSize: '20px' }} />
              <Typography sx={{fontSize: '11px'}}>Number of Marketplaces:</Typography>
              </Box>
            </Grid>
            <Grid item xs={4}><Typography style={valueFont} >{dialogState?.data?.market_places ? dialogState?.data.market_places?.length : "--"}</Typography></Grid>
          </Grid>

          <Grid item xs={12} sx={mainGrid} >
            <Grid item xs={8}>
              <Box sx={{ display: 'flex', mt: '6px', alignItems: 'center' }} >
              <KeyboardDoubleArrowRightOutlinedIcon color='primary' sx={{ fontSize: '20px' }} />
              <Typography sx={{fontSize: '11px'}}>Rule Name:</Typography>
              </Box>
            </Grid>
            <Grid item xs={4}><Typography style={valueFont} >{dialogState?.data?.rule_name}</Typography></Grid>
          </Grid>

          <Grid item xs={12} sx={mainGrid} >
            <Grid item xs={8}>
              <Box sx={{ display: 'flex', mt: '6px', alignItems: 'center' }} >
              <KeyboardDoubleArrowRightOutlinedIcon color='primary' sx={{ fontSize: '20px' }} />
              <Typography sx={{fontSize: '11px'}}>Repeat on:</Typography>
              </Box>
            </Grid>
            <Grid item xs={4}><Typography style={valueFont} >{binaryStringToWeekdays(dialogState?.data?.weekday).join(', ')}</Typography></Grid>
          </Grid>

        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default ViewRuleDetails;