import { Box, Button, Checkbox, Dialog, DialogContent, Divider, Grid, IconButton, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';

const PrivateLabelFilterDialog = ({openFilterDialog, setOpenFilterDialog}) => {

    const themeMode = useSelector(state => state.theme.mode);
    const fontColor = { color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' }

    const handleClose = () => {
        setOpenFilterDialog(prev => ({ ...prev, openPrivateDialog: false }));
    }    

    const brand = ['Abbott','Abreva','Act','Advil','Afrin'];
    const category = ['Alba','Appliances','Beverages','Bladder Health','Candy'];
    const departments = ['Baby & Kids','Beauty','Grocery','Medicine & Health','Shop By Brand','Vitamins '];
    const marketPlaces = ['CVS','Dollar General','Walgreens'];
    const locations = ['Oakhurst','Seattle','New York'];

    const [selectedState, setSelectedState] = useState({
        brand: true,
        category: false,
        dept: false,
        loc: false,
        mktplace: false
    })
    
    const handleSelectedState = (type) => {
        // Set selectedState[type] to true and reset others to false
        setSelectedState({
            dept: type === 'dept',
            loc: type === 'loc',
            mktplace: type === 'mktplace',
            brand: type === 'brand',
            category: type === 'category'
        });
        console.log(selectedState)
    }
    const clearFilter = () => {

    }
    
    const applyFilter = () => {
      handleClose();
    }

  return (
    <div>
        <Dialog
        open={openFilterDialog.openPrivateDialog}
        maxWidth='md'
        onClose={handleClose}
          PaperProps={{
            sx: {
              ...fontColor, width: '100%',
              backgroundColor: themeMode === 'dark' ? '#232D3B' : '#fff',
            }
          }}
        >
        <DialogContent >
        <Grid container sx={{ justifyContent: 'space-between', borderBottom: '0.5px solid #eaecf0', pb: '4px' }} >
            <div style={{display: 'flex', alignItems: 'center', gap: '6px'}} >
            <FilterListIcon style={{ fontSize: '20' }} />
            <Typography sx={{ fontWeight: 'bold' }}>Private Label Filters</Typography>
            </div>
            <IconButton onClick={handleClose} ><CloseIcon style={{...fontColor, fontSize: '20px'}} /></IconButton>
        </Grid>
        <Grid container>
            <Grid xs={2} item sx={{display: 'flex', gap: '12px', flexDirection: 'column', borderRight: '0.5px solid #eaecf0'}} >
            <Typography size="small" onClick={() => handleSelectedState('brand')} sx={{ fontSize: '13px', padding: "6px", marginRight: '10px', cursor: 'pointer', borderRadius: "6px", backgroundColor: selectedState.brand ? '#dbf3ff' : 'transparent', mt: 2, color: selectedState.brand ? '#3195c8' : '#808080' }}>Brand</Typography>
            <Typography size="small" onClick={() => handleSelectedState('category')} sx={{ fontSize: '13px', padding: "6px", marginRight: '10px', cursor: 'pointer', borderRadius: "6px", backgroundColor: selectedState.category ? '#dbf3ff' : 'transparent', color: selectedState.category ? '#3195c8' : '#808080' }}>Category</Typography>
            <Typography size="small" onClick={() => handleSelectedState('dept')} sx={{ fontSize: '13px', padding:"6px", marginRight: '10px', cursor: 'pointer', borderRadius:"6px", backgroundColor: selectedState.dept ? '#dbf3ff' : 'transparent', color: selectedState.dept ? '#3195c8' : '#808080' }}>Department</Typography>
            <Typography size="small" onClick={() => handleSelectedState('loc')} sx={{ fontSize: '13px', padding:"6px", marginRight: '10px', cursor: 'pointer', borderRadius:"6px", backgroundColor: selectedState.loc ? '#dbf3ff' : 'transparent', color: selectedState.loc ? '#3195c8' : '#808080' }}>Location</Typography>
            <Typography size="small" onClick={() => handleSelectedState('mktplace')} sx={{ fontSize: '13px', padding:"6px", marginRight: '10px', cursor: 'pointer', borderRadius:"6px", backgroundColor: selectedState.mktplace ? '#dbf3ff' : 'transparent', color: selectedState.mktplace ? '#3195c8' : '#808080' }}>Market Place</Typography>
            </Grid>
            <Grid xs={10} item sx={{p: 2, height: '250px', overflowY: 'scroll'}} className='custom-scrollbar-light' >
            {
                selectedState.brand && brand.map((obj) => (
                    <Box sx={{ display: 'flex', alignItems: 'center', height: '30px' }} key={obj}>
                        <Checkbox name='brand' size='small' sx={{ ...fontColor, '& .MuiSvgIcon-root': { fontSize: '18px' } }} />
                        <Typography sx={{ textTransform: 'capitalize', fontSize: '13px' }}>{obj}</Typography>
                    </Box>
                ))
            }
            {
                selectedState.category && category.map((obj) => (
                    <Box sx={{ display: 'flex', alignItems: 'center', height: '30px' }} key={obj}>
                        <Checkbox name='category' size='small' sx={{ ...fontColor, '& .MuiSvgIcon-root': { fontSize: '18px' } }} />
                        <Typography sx={{ textTransform: 'capitalize', fontSize: '13px' }}>{obj}</Typography>
                    </Box>
                ))
            }
            {
                selectedState.dept && departments.map((obj) => (
                    <Box sx={{ display: 'flex', alignItems: 'center', height: '30px' }} key={obj}>
                        <Checkbox name='dept' size='small' sx={{ ...fontColor, '& .MuiSvgIcon-root': { fontSize: '18px' } }} />
                        <Typography sx={{ textTransform: 'capitalize', fontSize: '13px' }}>{obj}</Typography>
                    </Box>
                ))
            }
            {
                selectedState.loc && locations.map((obj) => (
                    <Box sx={{ display: 'flex', alignItems: 'center', height: '30px' }} key={obj}>
                        <Checkbox name='loc' size='small' sx={{ ...fontColor, '& .MuiSvgIcon-root': { fontSize: '18px' } }} />
                        <Typography sx={{ textTransform: 'capitalize', fontSize: '13px' }}>{obj}</Typography>
                    </Box>
                ))
            }
            {
                selectedState.mktplace && marketPlaces.map((obj) => (
                    <Box sx={{ display: 'flex', alignItems: 'center', height: '30px' }} key={obj}>
                        <Checkbox name='mktplace' size='small' sx={{ ...fontColor, '& .MuiSvgIcon-root': { fontSize: '18px' } }} />
                        <Typography sx={{ textTransform: 'capitalize', fontSize: '13px' }}>{obj}</Typography>
                    </Box>
                ))
            }
            </Grid>
        </Grid>
        <Divider sx={{ borderColor: themeMode === 'dark' ? '#fff' : '' }} />

        <Grid sx={{ display: 'flex', mt: 2 }}>
            <Button onClick={clearFilter} sx={{ ml: 'auto', mr: 2, textTransform: 'none', fontSize: '13px' }}>Clear Filters</Button>
            <Button onClick={applyFilter} variant='contained' sx={{ textTransform: 'none', fontSize: '13px' }}>Apply Filters</Button>
        </Grid>
        </DialogContent>
        </Dialog>
    </div>
  )
}

export default PrivateLabelFilterDialog