import { FORGOT_PASSWORD, FORGOT_PASSWORD_FAILED, FORGOT_PASSWORD_SUCCESSFULL, RESET_FORGOT_PASSWORD } from "../constants/forgotPassword.constants";
import { RESET_ALL_DATA } from "../constants/resetAllData.constants";

const initialState = {
    loading: false,
    data: null,
    error: null,
};

const forgotPasswordReducer = (state = initialState, action) => {
    switch (action.type) {
        case FORGOT_PASSWORD:
            return {
                ...state,
                loading: true,
                error: null,
            };

        case FORGOT_PASSWORD_SUCCESSFULL:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null
            };

        case FORGOT_PASSWORD_FAILED:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload
            };

        case RESET_FORGOT_PASSWORD:
            return {
                ...initialState
            }

            case RESET_ALL_DATA:
                return{
                    ...initialState
                }

        default:
            return state;
    }
}

export default forgotPasswordReducer;