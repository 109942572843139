import { Grid, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux';

const AveragePricingPvtLabel = () => {

  const themeMode = useSelector(state => state.theme.mode);

    const tableData = [
        {id:'1',dept:'Baby & Kids & Toys',category:[{title:'Baby & Kids',cpi:'2',avgPrice:'16.79'},{title:'Toys',cpi:'1.28',avgPrice:'12.99'},{title:'Diapers',cpi:'1.78',avgPrice:'14.99'}]},
        {id:'2',dept:'Beauty',category:[{title:'Beauty', cpi:'1',avgPrice:'10'}, {title:'Body Wash', cpi:'4',avgPrice:'1.25'}, {title: 'Shampoo', cpi:'1',avgPrice:'1.56'}, {title:'Conditioner',cpi:'3', avgPrice:'1.56'}]},
        {id:'3',dept:'Grocery',category:[{title:'Grocery',cpi:'3', avgPrice:'1'}, {title:'Chew & Gum',cpi:'3', avgPrice:'1.56'}, {title:'Chips',cpi:'3', avgPrice:'1.56'}, {title:'Cookies',cpi:'3', avgPrice:'1.56'}, {title:'Crackers',cpi:'3', avgPrice:'1.56'}, {title:'Healthy Snacks',cpi:'3', avgPrice:'1.56'}, {title:'Water',cpi:'3', avgPrice:'1.56'}, {title:'Cereal',cpi:'3', avgPrice:'1.56'}]},
        {id:'4',dept:'Medicine & Health',category:[{title:'Medicine & Health',cpi:'3', avgPrice:'1.56'}, {title:'Allergy Medicine',cpi:'3', avgPrice:'1.56'}, {title:'Bandages',cpi:'3', avgPrice:'1.56'}, {title:'Fiber',cpi:'3', avgPrice:'1.56'}, {title:'Water Based',cpi:'3', avgPrice:'1.56'}, {title:'Sleep Medicine',cpi:'3', avgPrice:'1.56'}]},
        {id:'5',dept:'Personal Care',category:[{title:'Personal Care',cpi:'3', avgPrice:'1.56'}, {title:'Body Wash',cpi:'3', avgPrice:'1.56'}, {title:'Dental Guardian',cpi:'3', avgPrice:'1.56'}, {title:'Essential Oils',cpi:'3', avgPrice:'1.56'}, {title:'Eye Wash',cpi:'3', avgPrice:'1.56'}, {title:'Mouth Wash',cpi:'3', avgPrice:'1.56'}]},
      ]


      return (
        <Grid container sx={{ border: "2px solid black" }}>
          <Grid item xs={12} sx={{ textAlign: "center", fontWeight:"bold", border:'1px solid black', padding:"8px", backgroundColor: "#070544", color: "white", fontSize:"14px" }}>
            Average Pricing and C.P.I of Private Label
          </Grid>
          <Grid item container xs={12} sx={{border:'1px solid black', }}>
            <Grid xs={1} item sx={{fontSize:"14px", fontWeight:'bold',padding: '8px', background: 'white'}} >Department</Grid>
            <Grid xs={1} item sx={{fontSize:"14px", fontWeight:'bold',padding: '8px', background: 'white'}} >Category</Grid>
            <Grid xs={5} item sx={{fontSize:"14px", fontWeight:'bold',padding: '8px',textAlign:"center", background: 'white'}} >C.P.I</Grid>
            <Grid xs={5} item sx={{fontSize:"14px", fontWeight:'bold',padding: '8px',textAlign:"center", background: 'white'}} >Average Price</Grid>
          </Grid>
          <div style={{width:"100%",maxHeight:"300px", overflowY:'scroll'}} className={`custom-scrollbar-${themeMode}`} >
            {tableData.map((item, itemIndex) => (
              <Grid item container xs={12} key={item.id} sx={{border:'1px solid black', }}>
                {item.category.map((categoryItem, index) => (
                  <React.Fragment key={`${item.id}-${index}`}>
                    <Grid item xs={1} sx={{padding: '8px', fontSize: '10px', background: 'white' }} rowSpan={item.category.length}>{index === 0 ? item.dept : null}</Grid>
                    <Grid item xs={1} sx={{padding: '8px', fontSize: '10px', borderRight:'1px solid black' ,background: (itemIndex * item.category.length + index) % 2 === 0 ? "#f2f2f2" : "white" }}>{categoryItem.title}</Grid>
                    <Grid item xs={5} sx={{display:'grid', padding: '8px', borderRight:'1px solid black' ,background: (itemIndex * item.category.length + index) % 2 === 0 ? "#f2f2f2" : "white" }}>
                        <Tooltip title={
                          <>
                            <Typography sx={{fontSize: '10px'}} >Category Name: {categoryItem.title}</Typography>
                            <Typography sx={{fontSize: '10px'}} >C.P.I: {categoryItem.cpi}</Typography>
                          </>
                        }>
                      <div style={{display: "flex", alignItems: "center", justifyContent:"center" ,fontSize:"12px", cursor:"pointer"}}>
                          {categoryItem.cpi}
                      </div>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={5} sx={{display:'grid', padding: '8px', background: (itemIndex * item.category.length + index) % 2 === 0 ? "#f2f2f2" : "white" }}>
                        <Tooltip title={
                          <>
                            <Typography sx={{fontSize: '10px'}} >Category Name: {categoryItem.title}</Typography>
                            <Typography sx={{fontSize: '10px'}} >Average Price: {categoryItem.avgPrice}</Typography>
                          </>
                        }>
                      <div style={{display: "flex", alignItems: "center", justifyContent:"center", fontSize:"12px", cursor:"pointer"}}>
                          {categoryItem.avgPrice}
                      </div>
                        </Tooltip>
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            ))}
          </div>
        </Grid>
      );
    }
    
    export default AveragePricingPvtLabel;