import axios from "axios";
import { FETCH_DASHBOARD_LOCATION,FETCH_DASHBOARD_LOCATION_FAILED,FETCH_DASHBOARD_LOCATION_SUCCESSFULL } from "../constants/dashboardLocation.constants";
import { paths } from "../../Api/paths";
import { handleTokenExpiration } from "./handleTokenExpiration.actions";

const getDashboardLocation = () => ({
    type: FETCH_DASHBOARD_LOCATION
});

const getDashboardLocationSuccessfull = (data) => ({
    type: FETCH_DASHBOARD_LOCATION_SUCCESSFULL,
    payload: data
});

const getDashboardLocationFailed = (error) => ({
    type: FETCH_DASHBOARD_LOCATION_FAILED,
    payload: error
});

export const fetchDashboardLocation = ( token, qa_user, company_id, loc_id ) => async (dispatch) => {
    try{
        dispatch(getDashboardLocation());
        const endpoint = qa_user ? `${paths.qaDashboardLocation}?company_id=${company_id}&loc_id=${loc_id}` : `${paths.dashboardLocation}?loc_id=${loc_id}`;
        const response = await axios.get(`${process.env.REACT_APP_API_LINK}${endpoint}`,
        { headers: { Authorization: `bearer ${token}` }});
        dispatch(getDashboardLocationSuccessfull(response.data));
    }catch (error){
        if (error.response && error.response.status === 401) {
            dispatch(handleTokenExpiration());
          } else {
        dispatch(getDashboardLocationFailed(error));
          }
    }
}